<template>
    <div class="about">

        <AppHeading title="Our Services" />

        <AppOurServices />

    </div>
</template>

<script>
export default {
    components: {
        AppHeading: () => import("@/components/Heading.vue"),
        AppOurServices: () => import("@/components/OurServices.vue"),
    }
}
</script>